@import 'Styles/includes';

.Image {
    $root: &;

    width: 100%;
    height: auto;

    &__LoadEvent {
        display: none !important;
    }

    &--Cover {
        height: 100%;
    }

    &--Contain {
        object-fit: contain;
        background-size: contain;
    }

    &--Cover#{&}--ObjectFit {
        object-fit: cover;
    }

    &--Cover#{&}--Fallback {
        background-size: cover;
    }
}

@import 'Styles/includes';

.SubMenu {
    $root: &;

    background: white;

    &__List {

    }

    &__Item {
        position: relative;
        display: block;
        transition: background-color $transition;

        &--Search {
            background: transparent !important;
        }

        #{$root}--MobileMenu &--Open {
            background-color: $purple-10;
        }

        #{$root}--MobileMenu & {
            border-bottom: 1px solid $grey-15;
        }

        #{$root}--MobileMenu #{$root}__List--Level-2 & {
            border: none;
        }
    }

    &__Link {
        @include textstyle(navigation-titles);

        position: relative;
        display: block;
        padding: 21px 24px 20px; /* Padding left is set in js */
        border-bottom: 1px solid $grey-30;
        outline: none;

        #{$root}--MobileMenu & {
            border-bottom: 0;
            padding: 21px 15px 20px; /* Padding left is set in js */
        }

        #{$root}__Item--Root > & {
            font-weight: $heavy;
        }

        #{$root}__Item--Current > & {
            color: $grey-70 !important;

            &::before {
                opacity: .24;

                #{$root}--MobileMenu & {
                    opacity: 1;
                }
            }
        }

        #{$root}__List--Level-2 & {
            @include textstyle(navigation-sub-titles);

            padding-top: 23px;
            padding-bottom: 22px;

            #{$root}--MobileMenu & {
                padding-top: 21px;
                padding-bottom: 21px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $purple-20;
            opacity: 0;
            transition: opacity $transition;

            #{$root}__Item--Search & {
                display: inline-block;
                margin-right: 10px;
                position: relative;
                top: 2px;
                bottom: auto;
                left: auto;
                right: auto;
                width: 15px;
                height: 15px;
                background: url('#{$assetsPath}img/search--grey40.svg') no-repeat center center;
                background-size: contain;
                opacity: 1;
            }
        }

        &:hover,
        &:focus {
            &::before {
                opacity: 1;

                @media (hover: none) {
                    opacity: 0;
                }
            }
        }
    }

    &__Text {
        position: relative;
    }

    &__Toggle {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 44px;
        height: 44px;
        outline: none;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $purple-20;
            opacity: 0;
            transition: opacity $transition;
        }

        svg {
            display: block;
            width: 7px;
            height: 14px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            fill: $grey;
            transform: rotate(90deg);
            transition: transform $transition;

            #{$root}__Item--Open > & {
                transform: rotate(-90deg);
            }

            #{$root}--MobileMenu & {
                width: 8px;
                height: 16px;
            }

            #{$root}--MobileMenu #{$root}__Item--Open > & {
                fill: $purple;
            }
        }

        &:hover,
        &:focus {
            &::before {
                opacity: 1;
            }

            svg {
                fill: $purple;
            }
        }
    }
}

@import 'Styles/includes';

.CardArchitecturePrize {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__Tab {
        @include textstyle(label);

        position: absolute;
        top: 0;
        right: 0;
        width: 122px;
        padding: 16px 16px 68px 35px;
        color: black;
        text-align: right;
        pointer-events: none;
        z-index: 1;

        @include media(ML) {
            width: 130px;
            padding: 24px 24px 76px 43px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: calc(100% - 90px);
            background: $salmon-40;

            @include media(ML) {
                height: calc(100% - 98px);
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(100% - 90px);
            left: 0;
            display: block;
            width: 0;
            height: 0;
            border-top: 68px solid $salmon-40;
            border-left: 122px solid transparent;

            @include media(ML) {
                top: calc(100% - 98px);
                border-left-width: 130px;
            }
        }

        span {
            position: relative;
            z-index: 1;
        }
    }

    &__Content {
        position: relative;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 40px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
            padding-bottom: 20px;
        }

        #{$root}--NoImage#{$root}--Winner & {
            padding-top: 80px;

            @include media(SL) {
                padding-top: 20px;
            }

            @include media(M) {
                padding-top: 80px;
            }

            @include media(XL) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
    }

    &__Header {
        margin-bottom: 11px;
        display: flex;
        flex-direction: column;

        @include media(L) {
            flex-direction: row;
        }
    }

    &__Label {
        @include textstyle(label);

        color: $purple;
        display: inline-block;
        margin-bottom: 16px;
        word-break: break-word;
    }

    &__Office {
        @include textstyle(label);

        color: $grey;
        display: inline-block;
        margin-top: 16px;
        margin-bottom: 4px;
    }

    &__Footer {
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        #{$root}--NoImage & {
            margin-top: 0;
        }
    }

    &__Location {
        @include textstyle(label);

        width: auto;
        white-space: nowrap;
        margin-top: 16px;

        &::before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            vertical-align: top;
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('#{$assetsPath}img/place.svg');
        }
    }

    &__Date {
        @include textstyle(label);

        margin-top: 16px;
        color: $grey-40;
        display: inline-block;

        #{$root}__Location + & {
            &::before {
                content: '•';
                display: inline-block;
                margin-left: 10px;
                margin-right: 8px;
                color: $grey-40;
            }
        }
    }

    &__Nomination {
        @include textstyle(label);

        margin-top: 16px;
        color: $grey-40;
        display: inline-block;

        #{$root}__Location + &,
        #{$root}__Date + &, {
            &::before {
                content: '•';
                display: inline-block;
                margin-left: 10px;
                margin-right: 8px;
                color: $grey-40;
            }
        }
    }

    &__Title {
        word-break: break-word;
        margin-bottom: 4px;

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }

        #{$root}--NoImage#{$root}--Winner & {
            @include media(SL) {
                padding-right: 100px;
            }

            @include media(M) {
                padding-right: 0;
            }

            @include media(XL) {
                padding-right: 60px;
            }
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }
}

@import 'Styles/includes';

.SearchForm {
    $root: &;

    display: flex;
    width: 100%;

    &--Menu {
        width: 68px;
        padding-left: 3px;
        transition: width $transition;

        &:hover,
        &:focus-within {
            width: 210px;
        }
    }

    &--Focus#{$root}--Menu,
    &--HasValue#{$root}--Menu {
        width: 210px;
    }

    // Same style in Search > Field
    &--RekAI {
        :global {
            .rekai-autocomplete {
                &.rekai-styled {
                    .rekai-dropdown-menu {
                        border: none;
                        border-radius: 0;
                        background: $grey-10;
                        box-shadow: 0 4px 4px 0 rgba(black, .25);

                        .rekai-suggestion {
                            @include textstyle(h5);

                            padding: 7px 14px;
                            border-top: 1px solid white;

                            &:first-child {
                                border: none;
                            }

                            &.rekai-cursor,
                            &:hover {
                                background-color: $purple-20;
                            }
                        }
                    }
                }
            }
        }
    }

    &--RekAI#{$root}--Menu {
        :global {
            .rekai-autocomplete {
                &.rekai-styled {
                    .rekai-dropdown-menu {
                        width: 390px;
                        max-width: 100vw;
                        left: -24px !important;
                    }
                }
            }
        }
    }

    &__Field {
        flex: 0 1 auto;
        width: 100%;
        position: relative;

        #{$root}:not(#{$root}--Menu) & {
            @extend %form__field;
            @extend %form__field--search;
        }

        #{$root}--Menu & {
            padding-left: 15px;
        }
    }

    &__Label {
        #{$root}:not(#{$root}--Menu) & {
            @extend %form__label;
            @extend %form__label--search;
            @extend %form__label--inside;
        }

        #{$root}:not(#{$root}--Menu) #{$root}__Input:focus + &,
        #{$root}--HasValue:not(#{$root}--Menu) & {
            @extend %form__label--inside-focus;
        }

        #{$root}--Menu & {
            position: absolute;
            top: 9px;
            left: 0;
            display: inline-block;

            &::before {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                position: relative;
                vertical-align: middle;
                background-size: contain;
                background-image: url('#{$assetsPath}img/search.svg');
                background-repeat: no-repeat;
                opacity: .6;
                transition: opacity $transition;
            }
        }

        #{$root}--Menu:hover &,
        #{$root}--Menu:focus-within & {
            &::before {
                opacity: 1;
            }
        }
    }

    &__LabelText {
        #{$root}--Menu & {
            @extend %sr-only;
        }
    }

    &__Input {
        #{$root}:not(#{$root}--Menu) & {
            @extend %form__input;
            @extend %form__input--search;

            margin: 0;
        }

        #{$root}--Menu & {
            @include textstyle(navigation-sub-titles);

            display: block;
            position: relative;
            width: 100%;
            padding: 12px 12px 13px 14px;
            color: black;
            line-height: 1.2;
            font-family: $base-font;
            border: none;
            border-radius: 0;
            background: white;
            outline: none;
            background-clip: padding-box;
            box-shadow: none;
            transition: color $transition;

            &:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 black;
            }

            // Remove close icon in IE
            &::-ms-clear {
                display: none;
            }

            &::placeholder {
                color: $grey;
                font-family: $base-font;
            }
        }
    }

    &__Submit {
        @extend %form__submit;

        width: 150px;
        min-width: 0;
        margin-left: $gutter;

        @include media(M) {
            width: 170px;
            margin-top: 0;
        }

        @include media-max(M) {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        #{$root}--Menu & {
            @extend %sr-only;
        }
    }
}

@import 'Styles/includes';

.CardCourse {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    &__Label {
        @include textstyle(label);

        align-self: flex-start;
        display: inline-block;
        margin-bottom: 16px;

        &--Highlighted {
            margin-top: -5px;
            margin-bottom: 11px;
            padding: 8px;
            background: $salmon-30;
        }
    }

    &__Title {
        @include textstyle(h3);
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 16px;
    }

    &__Footer {
        display: flex;
        flex-wrap: wrap;
        margin-top: auto;
        padding-top: 16px;
        margin-left: -19px;
    }

    &__Meta {
        @include textstyle(label);

        width: 100%;
        white-space: nowrap;
        margin-top: 8px;
        margin-left: 19px;

        @include media(L) {
            width: auto;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            vertical-align: top;
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--Date {
            &::before {
                background-image: url('#{$assetsPath}img/calendar.svg');
            }
        }

        &--Location {
            &::before {
                background-image: url('#{$assetsPath}img/place.svg');
            }
        }
    }

    &__Registered {
        @include textstyle(label);

        flex: 0 0 auto;
        align-self: flex-start;
        position: relative;
        display: inline-block;
        margin: 19px 0 0;
        padding: 12px 16px;
        color: black;
        background: $salmon-30;

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 16px;
            width: 15px;
            height: 15px;
            margin: auto;
            background: url('#{$assetsPath}img/check.svg') no-repeat center center;
            background-size: contain;
        }

        #{$root}--Approved & {
            padding-left: 41px;
            background: $green-40;

            &::before {
                display: inline-block;
            }
        }
    }

    &__Status {
        @include textstyle(label);

        position: relative;
        display: inline-block;
        margin: 19px 0 0 3px;
        color: $grey-40;

        &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 14px;

            #{$root}--FewSeats & {
                background: $blue;
            }

            #{$root}--Closed & {
                background: $salmon-40;
            }

            #{$root}--Full & {
                background: $salmon;
            }
        }
    }

    &__Button {
        @include button(quaternary);

        border-top: 1px solid $purple-20;

        #{$root}__Link:hover + #{$root}__Content + & {
            color: white;
            background-color: $purple;

            &::after {
                background-image: url('#{$assetsPath}img/arrowRight--white.svg');
                transform: translateX(8px);
            }
        }
    }
}

@import 'Styles/includes';

.Footer {
    $root: &;

    background-color: $grey-20;

    @media print {
        break-inside: avoid;
    }

    :global(.js-state-menumobile-open) & {
        display: none;
    }

    &__Wrap {
        @include wrap;
        padding: 40px 0;
    }

    &__Columns {
        padding-bottom: 40px;
        position: relative;

        @include media(SL) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    &__Column {
        flex: 0 0 auto;
        margin-bottom: 40px;
        margin-right: 24px;
        width: 100%;
        max-width: 190px;

        @include media(SL) {
            width: auto;
        }

        @include media(M) {
            width: calc(100% / 3);
        }

        @include media(ML) {
            width: auto;
            margin-right: 40px;
            margin-bottom: 0;
        }

        @include media(L) {
            width: calc(100% / 3);
            margin-right: 24px;
        }

        @media print {
            display: none;
        }

        &:first-child {
            @media print {
                display: block;
            }

            #{$root}--HasCookieScript & {
                @include media(ML) {
                    padding-bottom: 52px;
                }
            }
        }
    }

    &__Title {
        @include textstyle(label);
        margin-bottom: 16px;
    }

    &__Item {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__Link {
        display: inline-block;
        padding-top: 2px;
        transition: color $transition;

        span {
            display: inline-block;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;
            transition: border-color $transition;

            &:hover {
                border-color: $purple;
            }
        }

        &:hover {
            color: $purple;

            span {
                border-color: $purple;
            }
        }

        &[href] {
            @media print {
                display: none;
            }

            &::before,
            &::after {
                @media print {
                    display: none;
                }
            }
        }

        &[href^='mailto:'],
        &[href^='tel:'] {
            position: relative;
            padding-left: 30px;
            color: $purple;

            &::before {
                content: '';
                width: 18px;
                height: 18px;
                margin-right: 12px;
                position: absolute;
                top: 2;
                left: 0;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            /* Make sure mailto and tel don't include external icon */
            &::after {
                display: none !important;
            }

            @media print {
                padding: 0;
                display: block;
            }
        }

        &[href^='mailto:'] {
            &::before {
                top: 4px;
                width: 20px;
                height: 14px;
                background-image: url('#{$assetsPath}img/mail--purple.svg');
            }
        }

        &[href^='tel:'] {
            &::before {
                background-image: url('#{$assetsPath}img/phone--purple.svg');
            }
        }

        @include href-external {
            &::after {
                content: '';
                margin-left: 9px;
                width: 13px;
                height: 13px;
                display: inline-block;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('#{$assetsPath}img/external--purple.svg');
            }
        }
    }

    &__Follow {
        flex: 0 0 auto;
        margin-bottom: 40px;
        min-width: 100%;

        #{$root}--HasCookieScript & {
            padding-top: 80px;
        }

        @include media(M) {
            min-width: auto;
        }

        @include media(ML) {
            margin-left: auto;
            justify-self: flex-end;

            #{$root}--HasCookieScript & {
                padding-top: 0;
            }
        }

        @media print {
            display: none;
        }
    }

    &__Logo {
        position: absolute;
        bottom: 0;

        @include media(ML) {
            right: 0;
        }

        img {
            width: 220px;
            height: 28px;
        }
    }

    &__Text {
        margin-bottom: 52px;
        line-height: 1.75;
    }
}

@import 'Styles/includes';

.Menu {
    $root: &;

    position: relative;

    &__List {
        position: relative;

        #{$root}--Primary & {
            padding-right: 60px;
            display: flex;
        }

        &::after {
            #{$root}--Service & {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                height: 0;
                width: calc(100% + ((100vw - 100%) / 2));
                border-bottom: 2px solid $salmon;
                border-left: 3px solid transparent;
            }
        }
    }

    &__Item {
        display: block;
        position: relative;
        margin-left: 24px;
        border-bottom: 2px solid transparent;
        transition: border-color .3s ease-in-out;

        #{$root}--Service & {
            display: inline-block;
            border: none;
            margin-left: 8px !important;

            &:first-child {
                margin-left: 0 !important;
            }

            &:last-child {
                margin-right: -12px !important;
            }
        }

        &:hover {
            border-color: $purple;

            #{$root}__Link svg {
                fill: $purple;
            }
        }

        @include media(XL) {
            margin-left: 40px;
        }
    }

    &__Link {
        @include textstyle(navigation-titles);

        display: inline-block;
        padding: 30px 0 27px;

        #{$root}__Item--HasChildren & {
            padding-right: 25px;
            position: relative;
        }

        #{$root}--Service & {
            @include textstyle(navigation-sub-titles);

            padding: 12px;
            transition: color $transition;

            &:hover {
                color: black;
            }
        }

        svg {
            width: 7px;
            height: auto;
            fill: $grey;
            position: absolute;
            right: 2px;
            top: 3px;
            bottom: 0;
            margin: auto;
            transform: rotate(90deg);
            transition: fill $transition;
        }

        #{$root}__Item--Search &::before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: 3px;
            margin-right: 14px;
            vertical-align: middle;
            position: relative;
            bottom: 1px;
            background-size: contain;
            background-image: url('../#{$assetsPath}img/search.svg');
            opacity: .6;
            transition: opacity $transition;
        }

        #{$root}__Item--Search:hover &::before {
            opacity: 1;
        }
    }

    &__Sub {
        display: none;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        opacity: 0;
        z-index: $z-HeaderSub;

        #{$root}__Item--Hover#{$root}__Item--HasChildren & {
            display: block;

            :global {
                animation: fadeInMenu 0.25s 0.15s ease-in-out forwards;
            }

            &::after {
                content: '';
                box-shadow: 0 7px 16px 0 rgba(33, 33, 33, 0.05);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: $z-HeaderSubShadow;
            }
        }
    }

    &__SubItem {
        position: relative;
        border-bottom: 1px solid $grey-30;
        z-index: $z-HeaderSub;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__SubLink {
        @include textstyle(navigation-sub-titles);

        display: block;
        white-space: nowrap;
        min-width: 200px;
        padding: 21px 23px;
        background-color: $grey-10;
        transition: background-color $transition;

        &:hover {
            background-color: $purple-20;
        }
    }

    &__Search {
        padding-left: 3px;
    }
}

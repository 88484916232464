@import 'Styles/includes';

.CardBlog {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Image {
        position: relative;
        background: white;
        margin: 0 auto 24px;
        width: 80%;
        max-width: 170px;

        @include media(L) {
            margin-bottom: 40px;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 50%;
        }
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
        }
    }

    &__Label {
        @include textstyle(label);

        color: $purple;
        display: inline-block;
        padding-bottom: 11px;
    }

    &__Title {
        word-break: break-word;

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }
}

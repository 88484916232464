@import 'Styles/includes';

.Header {
    $root: &;

    background: white;

    &__Main {
        position: relative;
        border-bottom: 1px solid $grey-30;

        @include media(L) {
            padding-top: 39px;
        }
    }

    &__LogoWrap {
        position: absolute;
        bottom: 14px;
        left: 0;
        right: 0;

        @include media(L) {
            bottom: 18px;
        }
    }

    &__Logo {
        @include wrap;
    }

    &__LogoLink {
        display: inline-block;
        position: relative;
        z-index: $z-HeaderLogoLink;

        img {
            width: 193px;
            height: 25px;

            @include media(L) {
                width: 320px;
                height: 41px;
            }
        }
    }

    &__ServiceWrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        // overflow: hidden;
        display: none;

        @include media(L) {
            display: block;
        }
    }

    &__PrimaryWrap {
        @include wrap;

        padding: 18px 0 16px 193px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @include media(L) {
            padding: 0 0 0 320px;
        }
    }

    &__Service {
        @include wrap;

        display: flex;
        justify-content: flex-end;
    }

    &__Primary {
        margin-left: auto;
        display: none;

        @include media(L) {
            display: block;
        }
    }

    &__Member {
        position: relative;
        margin: -16px 32px -16px auto;

        @include media(S) {
            margin-right: 50px;
        }

        @include media(L) {
            margin: 0 -16px;
            position: absolute;
            right: 0;
            top: 0;
        }

        &::after {
            content: '';
            display: none;
            position: absolute;
            bottom: 0;
            left: 12px;
            right: 12px;
            height: 2px;
            background: $purple;
            opacity: 0;

            @include media(L) {
                display: inline-block;
            }
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }

    &__MemberLink {
        display: block;
        position: relative;
        padding: 19px 16px;
        cursor: pointer;

        @include media(L) {
            padding: 28px 16px;
        }

        &::before {
            content: '';
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('#{$assetsPath}img/profile.svg');
            width: 17px;
            height: 20px;
            vertical-align: middle;
            opacity: .6;
            transition: opacity $transition;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        &::after {
            content: '';
            display: none;
            position: absolute;
            bottom: 16px;
            right: 12px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $purple;

            @include media(L) {
                bottom: 25px;
            }

            #{$root}__Member--Active & {
                display: block;
            }
        }
    }

    &__Sub {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        opacity: 0;
        padding: 8px 0;
        background: white;
        z-index: $z-HeaderSub;

        :global {
            animation: fadeInMenu 0.25s 0.15s ease-in-out forwards;
        }

        &::after {
            content: '';
            box-shadow: 0 7px 16px 0 rgba(33, 33, 33, 0.05);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: $z-HeaderSubShadow;
        }

        #{$root}--MemberMenuOpen & {
            display: block;
        }
    }

    &__SubItem {
        position: relative;
        z-index: $z-HeaderSub;
    }

    &__SubLink {
        @include textstyle(navigation-titles);
        display: block;
        white-space: nowrap;
        min-width: 100px;
        padding: 8px 24px;
        background-color: white;
        transition: color $transition;

        span {
            display: inline-block;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;
            transition: border-color $transition;
        }

        &:hover {
            color: $purple;

            span {
                border-color: $purple;
            }
        }
    }

    &__Toggle {
        padding: 16px;
        margin-right: -16px;
        margin-top: -16px;
        margin-bottom: -16px;
        position: absolute;
        right: 0;
        top: 18px;

        &::after {
            content: '';
            display: block;
            position: relative;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('#{$assetsPath}img/burger.svg');
            opacity: .6;
            transition: opacity $transition;
            cursor: pointer;

            #{$root}--MobileMenuOpen & {
                background-image: url('#{$assetsPath}img/cross.svg');
            }
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }

        @include media(L) {
            display: none;
        }
    }

    &__MenuMobile {
        display: none;
        position: relative;

        /* Also hides .Base__Document and .Footer */
        #{$root}--MobileMenuOpen & {
            display: block;

            @include media(L) {
                display: none;
            }
        }
    }
}

@import 'Styles/includes';

.CardArchitectOffice {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Logo {
        position: relative;
        margin-bottom: 20px;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 150px;
        }
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__Content {
        position: relative;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 32px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
            padding-bottom: 20px;
        }
    }

    &__Label {
        @include textstyle(label);

        margin-bottom: 11px;
        color: $purple;
        word-break: break-word;
    }

    &__Title {
        word-break: break-word;

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }

    &__Footer {
        display: flex;
        align-items: center;
        min-height: 57px;
        padding: 20px 24px;
        border-top: 1px solid $grey-20;
        background: white;
    }

    &__County {
        @include textstyle(label);

        position: relative;
        padding: 0 16px 0 24px;
        line-height: 1.4;

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 3px;
            width: 12px;
            height: 14px;
            display: inline-block;
            background: url('#{$assetsPath}img/pin--grey40.svg') no-repeat center center;
            background-size: contain;
        }
    }
}

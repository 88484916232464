@import 'Styles/includes';

.Follow {
    $root: &;

    &__Label {
        @include textstyle(label);
        margin-bottom: 16px;
    }

    &__Item {
        display: inline-block;
        margin-right: 38px;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__Link {
        display: block;
        width: 26px;
        height: 26px;
        fill: $grey;
        transition: fill $transition;

        &:hover {
            fill: $purple;
        }
    }
}

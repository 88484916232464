@import 'Styles/includes';

.Base {
    $root: &;

    &__Document {
        :global(.js-state-menumobile-open) & {
            display: none;
        }
    }

    &__Main {
        display: block;
        position: relative;
        overflow: hidden; // For trimming parallax shapes outside of viewport

        #{$root}--NotFound &,
        #{$root}--Competition & {
            overflow: visible;
        }
    }
}

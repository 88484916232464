@import 'Styles/includes';

.CardNews {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__Tab {
        @include textstyle(label);

        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 15px 10px 21px;
        color: black;
        pointer-events: none;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: calc(100% - 12px);
            background: $salmon-30;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(100% - 12px);
            left: 0;
            display: block;
            width: calc(100% - 23px);
            height: 0;
            border-top: 12px solid $salmon-30;
            border-left: 23px solid transparent;
        }

        span {
            position: relative;
            z-index: 1;
        }
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 24px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
        }

        #{$root}--IsLarge#{$root}--NoImage & {
            @include media(L) {
                padding: 40px;
            }
        }

        #{$root}--IsLarge#{$root}--HasImage & {
            @include media(L) {
                padding: 24px 40px 40px;
            }
        }
    }

    &__Label {
        @include textstyle(label);

        margin-bottom: 11px;
        color: $purple;

        :global(.Theme--Arkitekturgalan) & {
            color: $arkitekturgalan-link;
        }
    }

    &__Title {
        word-break: break-word;

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }

    &__Date {
        @include textstyle(label);

        padding-top: 24px;
        margin-top: auto;
        color: $grey-40;

        #{$root}--NoImage & {
            margin-top: 0;
        }
    }
}

@import 'Styles/includes';

.CardBlogPost {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__Tab {
        @include textstyle(label);

        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 15px 10px 21px;
        color: black;
        pointer-events: none;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: calc(100% - 12px);
            background: $salmon-30;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(100% - 12px);
            left: 0;
            display: block;
            width: calc(100% - 23px);
            height: 0;
            border-top: 12px solid $salmon-30;
            border-left: 23px solid transparent;
        }

        span {
            position: relative;
            z-index: 1;
        }
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 24px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
        }
    }

    &__Header {
        margin-bottom: 11px;
        display: flex;
        flex-direction: column;

        @include media(L) {
            flex-direction: row;
        }
    }

    &__Label {
        @include textstyle(label);

        color: $purple;
        display: inline-block;
        padding-bottom: 8px;

        @include media(L) {
            padding-bottom: 0;
        }

        &::after {
            content: '•';
            display: none;
            margin-left: 10px;
            margin-right: 8px;
            color: $grey-40;

            @include media(L) {
                display: inline-block;
            }
        }
    }

    &__Date {
        @include textstyle(label);

        margin-top: auto;
        color: $grey-40;
        display: inline-block;

        #{$root}--NoImage & {
            margin-top: 0;
        }
    }

    &__Title {
        word-break: break-word;

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }

    &__Author {
        padding-top: 24px;
        display: flex;
        align-items: center;

        #{$root}--NoImage & {
            padding-top: 16px;
        }
    }

    &__AuthorName {
        @include textstyle(body-small);

        display: inline-block;
        vertical-align: middle;
    }

    &__AuthorImage {
        position: relative;
        height: 44px;
        width: 44px;
        display: inline-block;
        margin-right: 12px;
        overflow: hidden;

        img {
            border-radius: 50%;
        }
    }
}

@import 'Styles/includes';

.CardCompetition {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__Content {
        position: relative;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 24px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
        }

        #{$root}--HasStatus & {
            padding-bottom: 64px;
        }
    }

    &__Label {
        @include textstyle(label);

        margin-bottom: 11px;
        color: $purple;
    }

    &__Title {
        word-break: break-word;

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }

    &__Status {
        @include textstyle(label);

        position: absolute;
        bottom: 24px;
        margin-top: auto;
        color: $grey-40;

        #{$root}--NoImage & {
            margin-top: 0;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 10px;
        }

        &--NotStarted::before {
            background: $blue;
        }

        &--Started::before {
            background: $green;
        }

        &--UnderEvaluation::before {
            background: $salmon-40;
        }

        &--Finished::before {
            background: $salmon;
        }
    }
}

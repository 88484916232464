@import 'Styles/includes';

.MenuMobile {
    $root: &;

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-MenuMobile;
    background-color: white;

    &__Wrap {
        @include wrap(d);

        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 0;
            width: calc(100% + ((100vw - 100%) / 2) - 15px);
            border-bottom: 2px solid $salmon;
            border-left: 3px solid transparent;
        }
    }

    &__Navigation {
    }

    &__Item {
        #{$root}__Navigation--Primary & {
            border-bottom: 1px solid $grey-15;
        }

        &--Has-Children {
            position: relative;
        }

        &--Visable {
            background-color: $purple-10;
        }

        &--Search {
            position: relative;
            border-bottom: 1px solid $grey-15;
        }
    }

    &__Link {
        @include textstyle(navigation-titles);
        display: block;
        padding: 19px 15px;

        #{$root}__Navigation--Service & {
            @include textstyle(navigation-sub-titles);
            padding: 23px 15px 20px;
        }

        &::before {
            #{$root}__Item--Search & {
                content: '';
                display: inline-block;
                margin-right: 10px;
                position: relative;
                top: 2px;
                width: 15px;
                height: 15px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('../#{$assetsPath}img/search--grey40.svg');
            }
        }
    }

    &__Toggle {
        display: none;
        position: absolute;
        top: 10px;
        right: 0;
        width: 60px;
        height: 40px;
        border-left: 1px solid $grey-15;

        #{$root}__Item--Has-Children & {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            width: 8px;
            height: auto;
            fill: $grey;
            transform: rotate(90deg);
            transition: fill $transition, transform $transition;

            #{$root}__Item--Visable & {
                fill: $purple;
                transform: rotate(-90deg);
            }
        }
    }

    &__Sub {
        display: none;

        #{$root}__Item--Visable & {
            display: block;
        }
    }

    &__SubLink {
        @include textstyle(navigation-sub-titles);
        display: block;
        white-space: nowrap;
        padding: 21px 23px 21px 36px;
        background-color: $purple-10;
    }
}

@import 'Styles/includes';

.Breadcrumbs {
    $root: &;

    &--InHero {
        background: white;
        z-index: $z-Breadcrumbs;

        @include media(ML) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: transparent;
        }
    }

    &__Wrap {
        @include wrap;
        position: relative;
    }

    &__List {
        padding: 8px 0 9px;
    }

    &__Item {
        display: inline-block;
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            position: relative;
            top: -2px;
            margin: 0 8px;
            height: 4px;
            width: 4px;
            background-color: $grey-40;

            :global(.Theme--Arkitekturgalan) & {
                background-color: $arkitekturgalan-link;
            }

            @media print {
                content: ' • ';
                font-size: 10px;
            }
        }

        &:last-of-type::after {
            display: none;
        }
    }

    &__Link {
        @include textstyle(navigation-sub-titles);

        color: $grey-40;
        text-decoration: none;
        transition: color $transition;

        :global(.Theme--Arkitekturgalan) & {
            color: $arkitekturgalan-link;
        }

        &:not(#{$root}__Link--Current):hover {
            color: $purple;

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-link-hover;
            }
        }

        &--Current {
            text-decoration: none;
        }
    }
}

@import 'Styles/includes';

.CardPage {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 32px 0 rgba(black, .12);
        opacity: 0;
        transition: opacity $transition;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-Card;
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 53.5%;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 20px 24px 24px;
        background: white;

        #{$root}--NoImage & {
            justify-content: center;
        }
    }

    &__Label {
        @include textstyle(label);

        margin-bottom: 11px;
        color: $purple;

        :global(.Theme--Arkitekturgalan) & {
            color: $arkitekturgalan-link;
        }
    }

    &__Title {
        word-break: break-word;
        position: relative;
        padding-right: 22px;

        @include media(L) {
            padding-right: 32px;
        }

        #{$root}--NoImage & {
            @include textstyle(h2);
        }

        #{$root}--HasImage & {
            @include textstyle(h3);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 9px;
            height: 19px;
            margin: auto;
            background-image: url('#{$assetsPath}img/arrowRight--purple.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: transform $transition;

            @include media(L) {
                right: 9px;
            }

            #{$root}:hover & {
                transform: translateX(8px);
            }

            :global(.Theme--Arkitekturgalan) & {
                background-image: url('#{$assetsPath}img/arrowRight--arkitekturgalan-blue.svg');
            }
        }
    }

    &__Text {
        @include textstyle(card-text);

        margin-top: 12px;
        color: $grey;
    }
}
